<template>
  <div>
    <div class="w-100 text-right">
      <modal-btn btn-class="btn-sm btn-info" target="#add-note" title="إضافة">
        <i class="fas fa-plus"></i>
      </modal-btn>
      <teleport to="#app">
        <confirm-model
          target-id="add-note"
          title="إضافة ملاحظة"
          confirm-text="إضافة"
          @confirmed="clickById('add-submit')"
        >
          <form @submit.prevent="store">
            <bootstrap-select
              id="note"
              :options="notes"
              v-if="!newNote"
              @changed="setNote"
            >
              <template #default>
                <option value="new">أخرى</option>
              </template>
            </bootstrap-select>
            <div class="pm-0" v-else>
              <form-text-area id="note"></form-text-area>
              <button class="link" @click="newNote = false">
                العودة للقائمة
              </button>
            </div>
            <button type="submit" hidden id="add-submit"></button>
          </form>
        </confirm-model>
      </teleport>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered">
        <tr>
          <th>الملاحظة</th>
          <th>تعديل</th>
        </tr>
        <tr v-if="emptyList">
          <td colspan="2">
            <strong>لا يوجد</strong>
          </td>
        </tr>
        <tr v-else v-for="note in list" :key="note.id" :id="`note-${note.id}`">
          <td class="pre-wrap">{{ note.note }}</td>
          <td>
            <modal-btn
              btn-class="btn-sm btn-primary"
              :target="`#edit-note-${note.id}`"
              title="تعديل"
            >
              <i class="fas fa-pen"></i>
            </modal-btn>
            <modal-btn
              btn-class="btn-sm btn-danger"
              :target="`#delete-note-${note.id}`"
              title="حذف"
            >
              <i class="fas fa-trash-alt"></i>
            </modal-btn>
            <teleport to="#app">
              <items-destroyer
                title="حذف ملاحظة"
                :url="`auction-notes/${note.id}`"
                :target-id="`delete-note-${note.id}`"
                :row-id="`note-${note.id}`"
                @deleted="deleted"
              >
                هل أنت متأكد من حذف الملاحظة ؟
              </items-destroyer>
              <confirm-model
                :target-id="`edit-note-${note.id}`"
                title="تعديل ملاحظة"
                confirm-text="تعديل"
                @confirmed="clickById(`edit-submit${note.id}`)"
              >
                <form @submit.prevent="update($event, note.id)">
                  <form-text-area
                    :id="`content${note.id}`"
                    name="note"
                    :value="note.note"
                  ></form-text-area>
                  <button
                    type="submit"
                    hidden
                    :id="`edit-submit${note.id}`"
                  ></button>
                </form>
              </confirm-model>
            </teleport>
          </td>
        </tr>
      </table>
    </div>
    <ThePaginator
      v-if="!emptyList"
      class="bg-e0"
      :url="defaultUrl"
      :total="total"
      :last-page="lastPage"
      :links="links"
    />
  </div>
</template>

<script>
import ThePaginator from "@/components/UI/ThePaginator";
import pagination from "@/mixins/pagination";
import ModalBtn from "@/components/UI/ModalBtn";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";
import ConfirmModel from "@/components/UI/ConfirmModel";
import FormTextArea from "@/components/Forms/FormTextArea";
import BootstrapSelect from "@/components/Forms/BootstrapSelect";

export default {
  components: {
    BootstrapSelect,
    FormTextArea,
    ConfirmModel,
    ItemsDestroyer,
    ModalBtn,
    ThePaginator,
  },
  mixins: [pagination],
  data() {
    return {
      formData: { auction: "" },
      newNote: false,
    };
  },
  computed: {
    defaultUrl() {
      return "/auction-notes";
    },
    auctionId() {
      return this.$route.params.auction;
    },
    // storageName() {
    //   return `edit-auction-notes${this.auctionId}`;
    // },
    notes() {
      return this.$store.getters.notes;
    },
  },
  methods: {
    async store(e) {
      if (!this.auctionId)
        return console.error("auction notes, empty auction id");
      const formData = new FormData(e.target);
      if (!formData.get("note"))
        return http.popupMessage("error", "يرجى كتابة ملاحظة");
      formData.set("auction", this.auctionId);
      const response = await http.send("auction-notes", formData);
      if (http.responseAccepted(response)) {
        this.newNote = false;
        this.setPagination(response.data);
        this.hideModal("#add-note");
        $_("#note").val("");
        $_("#note.selectpicker").selectpicker("val", "");
      }
    },
    async update(e, noteId) {
      if (!this.auctionId)
        return console.error("auction notes, empty auction id");
      const formData = new FormData(e.target);
      if (!formData.get("note"))
        return http.popupMessage("error", "يرجى كتابة ملاحظة");
      formData.set("_method", "PUT");
      formData.set("auction", this.auctionId);
      const response = await http.send(`auction-notes/${noteId}`, formData);
      if (http.responseAccepted(response, "data")) {
        this.setPagination(response.data);
        this.hideModal(`#edit-note-${noteId}`);
        $_(`edit-note-${noteId}`).val("");
      }
    },
    setNote(note) {
      this.newNote = note === "new";
    },
  },
  async created() {
    if (!!this.auctionId) {
      this.formData.auction = await this.auctionId;
      await this.setList();
      await this.$store.dispatch("setNotesAction");
    }
  },
};
</script>
